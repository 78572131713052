import React, { useEffect, useState } from 'react';
import ViewMultiDocument from '../../common/Modal/ViewMultiDocument';

const FundTable = (props) => {
    const { rejectRequest, fundList, classname } = props;
    const [viewDocs, setViewDocs] = useState(false);
    const [img, setImg] = useState(null);
    const [userId, setUserId] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (img) {
            setViewDocs(true);
        } else {
            setViewDocs(false);
            setUserId(null);
            setStatus(null);
        }
    }, [img])

    return (
        <>
            {viewDocs && <ViewMultiDocument show={viewDocs} handleClose={() => { setStatus(null); setImg(null); setViewDocs(false) }} imageData={img} rejectReq={() => { rejectRequest(userId); setViewDocs(false) }} status={status} />}

            <table className={`table ${classname}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Owner Name</th>
                        <th>Email</th>
                        <th>DOB</th>
                        <th>Crisis fund status</th>
                    </tr>
                </thead>
                <tbody>
                    {fundList && fundList?.length ? fundList.map((ele, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{ele?.ownerId?.basicInfo?.fullName}</td>
                            <td>{ele?.ownerId?.basicInfo?.email}</td>
                            <td>{ele?.ownerId?.basicInfo?.dob}</td>
                            <td>
                                <span style={{ marginLeft: 10, marginRight: 10 }} className={`${ele?.action === "approved" ? 'complete' : 'incomplete'}`}>
                                    {ele?.action === "approved" ? "Approved" : "Rejected"}
                                </span >
                                {ele.documentUrl?.length ? <i style={{ marginLeft: 10, cursor: "pointer" }} className='fa fa-eye'
                                    onClick={() => {
                                        setImg(null);
                                        setTimeout(() => {
                                            setStatus(ele?.action);
                                            setImg(ele.documentUrl);
                                            setUserId(ele._id);
                                        }, 0)
                                    }}
                                /> : ""}
                            </td>
                        </tr>
                    )) : null}
                </tbody>
            </table >
        </>
    )
}

export default FundTable;

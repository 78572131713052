import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ViewBookingDetails from '../../common/Modal/ViewBookingDetails'

const BookingHistory = (props) => {
  const { data } = props
  const [details, setDetails] = useState({})
  const [showViewModal, setShowViewModal] = useState(false)

  const handleClick = (details) => {
    setShowViewModal(true)
    setDetails(details)
  }

  const findStatusType = (type) => {
    switch (type) {
      case 'pending':
        return "Pending";
      case 'confirmed':
        return "Confirmed";
      case 'ongoing':
        return "Ongoing";
      case 'completed':
        return "Completed";
      case 'rejected':
        return "Rejected";
      case 'cancelled':
        return "Cancelled";
      default:
        return "";
    }
  }

  return (
    <>
      <table className='table'>
        <thead>
          <tr>
            <th>#</th>
            {/* <th>Owner</th> */}
            <th>Service Provider</th>
            <th>Fee</th>
            <th>Status</th>
            <th>Date/Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.length > 0 &&
            data.map((el, index) => (

              <tr key={el._id}>
                <td>{index + 1}</td>
                {/* <td>
                  {el && el.ownerId && el.ownerId.basicInfo
                    ? el.ownerId.basicInfo.fullName
                    : ''}
                </td> */}
                <td>
                  {el && el.serviceProviderId && el.serviceProviderId?.basicInfo
                    ? el.serviceProviderId?.basicInfo.fullName
                    : '-'}
                </td>
                <td>{el.price ? (el.price * el.noOfDogs) : '-'}</td>
                <td>
                  <span className={el && el.status}>{findStatusType(el.status)}</span>
                </td>
                <td>
                  {el && el.startDate} / {el && el.startTime}
                </td>
                <td>
                  <Link to='#' onClick={() => handleClick(el)}>
                    <i className='fa fa-eye' />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ViewBookingDetails
        showViewModal={showViewModal}
        setShowViewModal={setShowViewModal}
        details={details}
      />
    </>
  )
}

export default BookingHistory

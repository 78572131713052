import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Table from './Table'
import Pagination from '../../common/Pagination/Pagination'
import Search from '../../common/Search/Search'
import DeleteModal from '../../common/Modal/DeleteModal'
import AddOwnerModal from '../../common/Modal/AddOwner'
import { validateAddOwner } from '../../../validations/add-owner'
import {
  getOwner,
  unblockOwner,
  createOwner,
  deleteOwner,
  getAllOwners,
  searchOwners
} from '../../../actions/dashboardActions'
import './styles.scss'
import ExportToExcel from '../../common/Modal/ExportToExcel'
import Button from '../../common/Button/Button'

class ManageOwner extends Component {
  constructor() {
    super()
    this.state = {
      id: '',
      showDeleteModal: false,
      showAddOwnerModal: false,
      owner: {
        fullName: '',
        email: '',
        phoneNumber: ''
      },
      query: '',
      pageLimit: 20,
      currentPage: 1,
      showExportModal: false,
      columnArray: []
    }
    this.searchTimeout = null;
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    const data = sessionStorage.getItem('ownerCurrentPage') || this.state.currentPage
    // Get the data from sessionStorage
    this.setState({ currentPage: parseInt(data) })
    this.props.getAllOwners(token, data, this.state.pageLimit)
    this.setState({ walkers: this.props.dashboard.allOwners })
  }

  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id })
  }

  addOwnerModalClicked = () => {
    this.setState({ showAddOwnerModal: true })
  }

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false })
  }

  handleAddOwnerModalClose = () => {
    this.setState({ showAddOwnerModal: false })
  }

  handleYesBtnClick = () => {
    const token = localStorage.getItem('jwtToken')
    this.props.deleteOwner(this.state.id, token)
    this.setState({ showDeleteModal: false })
    this.props.getAllOwners(
      token,
      this.state.currentPage,
      this.state.pageLimit
    )
  }

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false })
  }

  unblockUser = (id) => {
    const token = localStorage.getItem('jwtToken')
    this.props.unblockOwner(id, token)
    this.props.getAllOwners(
      token,
      this.state.currentPage,
      this.state.pageLimit
    )
  }

  handleChange = (e) => {
    const owner = { ...this.state.owner }
    owner[e.target.name] = e.target.value
    this.setState({ owner })
  }

  handleAddOwnerSubmit = (e) => {
    e.preventDefault()

    const token = localStorage.getItem('jwtToken')

    const { fullName, email, phoneNumber } = this.state.owner

    const result = validateAddOwner(fullName, email, phoneNumber)
    if (result) return toast.error(result)

    const data = {
      ...this.state.owner,
      pageLimit: this.state.pageLimit,
      page: this.state.currentPage
    }

    this.props.createOwner(data, token)

    this.setState({ showAddOwnerModal: false })
  }

  handleSearch = (query) => {
    const token = localStorage.getItem('jwtToken')

    this.setState({ query })
    this.setState({ currentPage: 1 })
    // this.props.searchOwners(query, token)
    this.props.getAllOwners(token, 1, this.state.pageLimit, query)
  }

  handleSearch2 = (query) => {
    this.setState({ query, currentPage: 1 });

    // Clear the previous timeout if it's still active
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    // Set a new timeout to delay the API call
    this.searchTimeout = setTimeout(() => {
      this.handleSearchAPI(query);
    }, 1000); // 1-second delay
  }

  handleSearchAPI = (query) => {
    const token = localStorage.getItem('jwtToken');
    this.props.getAllOwners(token, 1, this.state.pageLimit, query ? query : "");
  }

  handlePageClick = (page) => {
    const token = localStorage.getItem('jwtToken')

    this.setState({ currentPage: page })

    this.props.getAllOwners(token, page, this.state.pageLimit)
  }

  deleteUser = (id) => {
    const token = localStorage.getItem('jwtToken')

    this.props.deleteOwner(id, token)

    this.props.getAllOwners(
      token,
      this.state.currentPage,
      this.state.pageLimit
    )
  }

  exportModalClicked = () => {
    const array = [
      { label: "Name", key: "basicInfo.fullName" },
      { label: "Email", key: "basicInfo.email" },
      { label: "DOB", key: "basicInfo.dob" },
      { label: "Average rating", key: "rating" },
      { label: "Date registered", key: "created_at", date: true },
      { label: "Status", key: "basicInfo.isUserBlocked", isCheck: true, if: "Suspended", else: "Active" }
    ]
    this.setState({ columnArray: array, showExportModal: true })
  }


  render() {
    const currentPageLength = this.props.dashboard.allOwners.length
    const totalOwners = sessionStorage.getItem('total_owners') || 0
    const totalNoOfOwner = sessionStorage.getItem('total_no_owners') || 0
    const currentRecordsFrom =
      this.state.pageLimit * (this.state.currentPage - 1) + 1
    const currentRecordsTo = currentRecordsFrom + currentPageLength - 1
    const profile = this.props.dashboard.adminProfileData
    return (
      <>
        <div className='manage-patient-wrapper'>
          <h5 class='text-left'>Manage Owners</h5>
          <div className='row mx-0'>
            <div className='col-md-6 pl-0 search_col'>
              <Search
                icon='fa fa-search icon'
                size='15px'
                placeholder='Search Owner'
                search={this.state.query}
                handleSearch={this.handleSearch2}
              />
            </div>

            <div className='col-md-6 pr-0 right_col'>
              <Button
                type="button"
                className="btn add-new-user-btn shadow-none"
                value="Export"
                handleClick={this.exportModalClicked}
              />
            </div>
          </div>
          <div className='manage-patient'>
            <div class='table-responsive'>
              {' '}
              <Table
                deleteModalClicked={this.deleteModalClicked}
                owners={this.props.dashboard.allOwners}
                unblockUser={this.unblockUser}
                adminProfileData={this.props.dashboard.adminProfileData}
                accessRole={this.props.accessRole}
                data={profile}
                classname='dashboard_table'
                currentPage={this.state.currentPage}
              />
            </div>
          </div>

          <div className='row result_row mx-0'>
            <div className='col-md-6 px-0 left_col'>
              {/* {!this.state.query && ( */}
                {this.props.dashboard.allOwners?.length ? <h6 className='results'>
                  Showing {currentRecordsFrom} - {currentRecordsTo} results of{' '}
                  {totalNoOfOwner}
                </h6> : ""}
              {/* // )} */}
            </div>
            <div className='col-md-6 px-0 right_col'>
              {/* {!this.state.query && ( */}
                {this.props.dashboard.allOwners?.length ? <Pagination
                  count={totalOwners}
                  totalItem={totalNoOfOwner}
                  pageLimit={this.state.pageLimit}
                  currentPage={this.state.currentPage}
                  handlePageClick={this.handlePageClick}
                /> : ""}
              {/* )} */}
            </div>
          </div>
        </div>

        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title='Suspend Account'
          text="Are you sure you want to suspend this owner's account?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />

        <AddOwnerModal
          showAddOwnerModal={this.state.showAddOwnerModal}
          fullName={this.state.fullName}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          handleAddOwnerModalClose={this.handleAddOwnerModalClose}
          handleAddOwnerSubmit={this.handleAddOwnerSubmit}
          handleChange={this.handleChange}
        />

        {this.state.showExportModal && <ExportToExcel handleClose={() => this.setState({ showExportModal: false })} show={this.state.showExportModal} columnList={this.state.columnArray} apiUrl={'/api/admin/get/selected-owner'} />}

      </>
    )
  }
}

ManageOwner.propTypes = {
  dashboard: PropTypes.object.isRequired,
  unblockOwner: PropTypes.func.isRequired,
  createOwner: PropTypes.func.isRequired,
  deleteOwner: PropTypes.func.isRequired,
  getAllOwners: PropTypes.func.isRequired,
  searchOwners: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole,

})

export default connect(mapStateToProps, {
  unblockOwner,
  createOwner,
  getOwner,
  deleteOwner,
  getAllOwners,
  searchOwners
})(ManageOwner)

import React from 'react';

const PackageTable = (props) => {
    const { list, classname, handleOpenModal } = props;

    const thTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '8px',
    };

    const findServiceType = (serviceProviderType) => {
        switch (serviceProviderType) {
            case 'walking':
                return "Walking";
            case 'grooming':
                return "Grooming";
            case 'sitting':
                return "House Sitting";
            case 'boarding':
                return "Boarding";
            case 'visiting':
                return "Drop-in Visits";
            case 'training':
                return "Training";
            default:
                return "Day";
        }
    }

    return (
        <>
            <table className={`table ${classname}`}>
                <thead>
                    <tr>
                        <th style={thTdStyle}>#</th>
                        <th style={thTdStyle}>Type</th>
                        <th style={thTdStyle}>Convinience Fee (%)</th>
                        <th style={thTdStyle}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list &&
                        list?.length ?
                        list.map((ele, index) => (
                            <tr >
                                <td style={thTdStyle} >{index + 1}</td>
                                <td style={thTdStyle} >{findServiceType(ele.serviceProviderType)}</td>
                                <td style={thTdStyle} className='max-w'>{ele.convenienceFeePercentage}</td>
                                <td style={thTdStyle} >
                                    <div onClick={() => handleOpenModal(ele._id, ele.convenienceFeePercentage, ele.serviceProviderType)} className='btn'>
                                        <i className='fa fa-edit' />
                                    </div>
                                </td>
                            </tr>
                        )) : null}
                </tbody>
            </table>
        </>
    )
}

export default PackageTable;

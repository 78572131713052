

import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getWalker, updateWalker } from '../../../actions/dashboardActions';
import { api_url } from '../../../utils/api';
import AddPackageModal from '../../common/Modal/AddPackage';
import Search from '../../common/Search/Search';
import PackageTable from './PackageTable';
import './styles.scss';
import DeleteModal from '../../common/Modal/DeleteModal';
import ViewPackageDetails from '../../common/Modal/ViewPackageDetailModal';

const Package = () => {
    const token = localStorage.getItem('jwtToken');
    const [searchValue, setSearchValue] = useState('');
    const [list, setList] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = React.useState({
        show: false,
        id: ''
    });
    const [showViewModal, setShowViewModal] = React.useState({
        show: false,
        details: ''
    });
    const [editModal, setEditModal] = React.useState({
        show: false,
        details: ''
    });
    const [packageList, setPackageList] = useState([]);
    const [showAddPackageModal, setShowAddPackageModal] = useState({
        show: false,
        details: ''
    });
    const handleSearch = (e) => {
        setSearchValue(e);
        setPackageList(list.filter((item) => item.title.toLowerCase().includes(e.toLowerCase())));
    }

    const getTableData = () => {
        axios.get(api_url + `/api/admin/package/get`, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    setPackageList(res.data.data.packageDetails);
                    setList(res.data.data.packageDetails);
                }
            })
            .catch((err) => console.log(err))
    }

    const filterData = (filter) => {
        axios.get(api_url + `/api/admin/package/get?serviceProviderType=${filter}`, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    setPackageList(res.data.data.packageDetails);
                    setList(res.data.data.packageDetails);
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => { getTableData() }, []);

    const handleDelete = () => {
        axios.delete(api_url + `/api/admin/package/delete/${showDeleteModal.id}`, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    setShowDeleteModal({ show: false, id: '' });
                    getTableData();
                }
            })
            .catch((err) => console.log(err))
    };


    return (
        <>
            {showDeleteModal.show && <DeleteModal
                showDeleteModal={showDeleteModal.show}
                handleDeleteModalClose={() => setShowDeleteModal({ show: false, id: '' })}
                title='Delete Package'
                text="Are you sure you want to delete this package?"
                handleYesBtnClick={handleDelete}
                handleNoBtnClick={() => setShowDeleteModal({ show: false, id: '' })}
            />}
            {showAddPackageModal.show && <AddPackageModal
                show={showAddPackageModal.show}
                details={showAddPackageModal.details}
                handleClose={() => setShowAddPackageModal({
                    show: false,
                    details: ''
                })}
                setPackageList={setPackageList}
                getTableData={getTableData}
            />}


            {showViewModal.show &&
                <ViewPackageDetails showViewModal={showViewModal.show} handleClose={() => setShowViewModal({ show: false, details: "" })} details={showViewModal.details} />
            }

            <div className='manage-doctor-wrapper manage_walkers_page'>
                <h5 className='text-left'>Manage Packages</h5>
                <div className='row mx-0'>
                    <div className='col-md-6 pl-0 search_col'>
                        <Search
                            icon='fa fa-search icon'
                            size='15px'
                            placeholder='Search Package'
                            search={searchValue}
                            handleSearch={handleSearch}
                        />
                    </div>
                    <div className='col-md-6 pr-0 right_col'>
                        <Button
                            type="button"
                            variant="primary"
                            className="btn btn-primary add-new-user-btn shadow-none"
                            value="Add Package"
                            onClick={() => setShowAddPackageModal({
                                show: true,
                                details: ''
                            })}
                        >
                            Add Package
                        </Button>
                    </div>
                </div>
                <div className='manage-doctor custom_class'>
                    <div className='table-responsive'>
                        <PackageTable filterData={filterData} packageList={packageList} classname='dashboard_table' handleDelete={handleDelete} setShowDeleteModal={setShowDeleteModal} setShowViewModal={setShowViewModal} setShowAddPackageModal={setShowAddPackageModal} />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
    memo(Package)
)

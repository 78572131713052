import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Table from './Table'
import Search from '../../common/Search/Search'
import Button from '../../common/Button/Button'
import Pagination from '../../common/Pagination/Pagination'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ViewBookingDetails from '../../common/Modal/ViewBookingDetails'
import ReviewModal from '../../common/Modal/ReviewModal'
import {
  getBookings,
  searchBooking,
  filterBookings,
  approveRating
} from '../../../actions/dashboardActions'
import './styles.scss'

class Bookings extends Component {
  constructor() {
    super()
    this.state = {
      query: '',
      pageLimit: 10,
      currentPage: 1,
      fromDate: '',
      toDate: '',
      date: new Date(),
      month: new Date(),
      year: new Date(),
      bookingDetails: {},
      showReviewModal: false,
      title: '',
      review: '',
      id: '',
      type: '',
      isRatingApproved: true,
      showViewModal: false,
      appointmentType: 'All Bookings',
      filterAppointments: {
        by: '',
        value: new Date()
      },
      filterBookings: {},
      isFiltered: false,
      status: ''
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    const data = sessionStorage.getItem('bookingCurrentPage') || 1
    this.setState({ currentPage: parseInt(data) })
    this.props.getBookings(
      token,
      // this.state.pageLimit,
      // data,
      // this.state.filterAppointments.by,
      // this.state.filterAppointments.value
      this.state.pageLimit,
      // data,
      this.state.currentPage,
      this.state.fromDate,
      this.state.toDate,
      this.state.status
    )
  }

  handleAppointmentChange = (appointment) => {
    const filterAppointments = { ...this.state.filterAppointments }
    filterAppointments.by = ''

    this.setState({ appointmentType: appointment, filterAppointments })
  }

  handleAppointmentReportChange = (filter) => {
    const filterAppointments = { ...this.state.filterAppointments }
    filterAppointments.by = filter

    this.setState({ filterAppointments })
  }

  handleFromDateChange = (date) => {
    this.setState({ fromDate: date })
  }

  handleToDateChange = (date) => {
    this.setState({ toDate: date })
  }

  handleYearChange = (year) => {
    const filterAppointments = { ...this.state.filterAppointments }
    filterAppointments.value = year

    this.setState({ year, filterAppointments })
  }

  showBookingDetails = (details) => {
    this.setState({ bookingDetails: details, showViewModal: true })
  }

  handleReview = (review, title, id, type, isRatingApproved) => {
    this.setState({ review, showReviewModal: true, title, id, type, isRatingApproved })
  }

  handleReviewModalClose = () => {
    this.setState({ showReviewModal: false })
  }

  handleViewModalClose = () => {
    this.setState({ showViewModal: false })
  }

  handleFilter = (e) => {
    e.preventDefault()
    const token = localStorage.getItem('jwtToken')
    // Call filter booking to get the booking between to and from dates
    // this.props.filterBookings(
    //   token,
    //   this.state.pageLimit,
    //   this.state.currentPage,
    //   this.state.fromDate,
    //   this.state.toDate
    // )
    this.props.getBookings(
      token,
      this.state.pageLimit,
      1,
      this.state.fromDate,
      this.state.toDate,
      this.state.status
    )
    this.setState({ isFiltered: true })
  }

  handleSearch = (query) => {
    this.setState({ query })
    const token = localStorage.getItem('jwtToken')
    this.props.searchBooking(query, token)
  }

  // Pagination
  handlePageClick = (page) => {
    this.setState({ currentPage: page })
    const token = localStorage.getItem('jwtToken')
    this.props.getBookings(
      token,
      // this.state.pageLimit,
      // page
      this.state.pageLimit,
      page,
      this.state.fromDate,
      this.state.toDate,
      this.state.status
    )
  }

  // To refresh the booking filter
  handleReload = () => {
    const token = localStorage.getItem('jwtToken')
    this.setState({ fromDate: '', toDate: '' })
    this.props.getBookings(
      token,
      // this.state.pageLimit,
      // this.state.currentPage
      this.state.pageLimit,
      1,
      "",
      "",
      this.state.status
    )
    this.setState({ isFiltered: false })
  }

  // change rating approve status
  handleApproveRating = (id) => {
    console.log('handleApproveRating', id)
    const token = localStorage.getItem('jwtToken')
    const type = this.state.type
    console.log('type', type)
    this.props.approveRating(id, type, token, (res) => {
      const data = sessionStorage.getItem('bookingCurrentPage') || this.state.currentPage
      this.setState({ currentPage: parseInt(data) })
      this.props.getBookings(
        token,
        // this.state.pageLimit,
        // data,
        // this.state.filterAppointments.by,
        // this.state.filterAppointments.value
        this.state.pageLimit,
        this.state.currentPage,
        this.state.fromDate,
        this.state.toDate,
        this.state.status
      )
      this.setState({ showReviewModal: false })
    })
  }

  handleStatusFilter = (status) => {
    this.setState({ status: status, currentPage: 1 })
    const token = localStorage.getItem('jwtToken')
    this.props.getBookings(
      token,
      this.state.pageLimit,
      1,
      this.state.fromDate,
      this.state.toDate,
      status
    )
  }



  render() {
    console.log('this.props.dashboard.bookings', this.props.dashboard.bookings)
    const currentPageLength = this.props.dashboard.bookings.data?.length
    const totalNoOfBooking = sessionStorage.getItem('total_no_bookings') || 0
    const totalBookings = sessionStorage.getItem('total_bookings') || 0
    const currentRecordsFrom =
      this.state.pageLimit * (this.state.currentPage - 1) + 1
    const currentRecordsTo = currentRecordsFrom + currentPageLength - 1

    return (
      <>
        <div className='appointments-wrapper'>
          <h5 className='text-left'>All Bookings</h5>
          <div className='appointments custom_class'>
            <form onSubmit={this.handleFilter}>
              <div className='row mx-0 mb-4 d-flex align-items-end'>
                <div className='col-md-2'>
                  <label className='date-picker-label'>From </label>
                  {/* <DatePicker
                    showIcon
                    selected={this.state.fromDate}
                    onChange={(date) => this.handleFromDateChange(date)}
                    className='form-control shadow-none'
                    icon="fa fa-calendar"
                  /> */}
                  <div className="datepicker-wrapper">
                    <DatePicker
                      selected={this.state.fromDate}
                      onChange={(date) => this.handleFromDateChange(date)}
                      className="form-control shadow-none datepicker-input"
                    />
                    <span className="calendar-icon">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div className='col-md-2'>
                  <label className='date-picker-label'>To </label>
                  {/* <DatePicker
                    showIcon
                    selected={this.state.toDate}
                    onChange={(date) => this.handleToDateChange(date)}
                    className='form-control shadow-none'
                    icon="fa fa-calendar"
                  /> */}
                  <div className="datepicker-wrapper">
                    <DatePicker
                      selected={this.state.toDate}
                      onChange={(date) => this.handleToDateChange(date)}
                      minDate={this.state.fromDate}
                      className="form-control shadow-none datepicker-input pl-30px"
                    />
                    <span className="calendar-icon">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div className='col-md-2 pr-0 right_col'>
                  <Button
                    type='submit'
                    value='Submit'
                    className='btn appointments-filter-submit-btn shadow-none'
                  />
                </div>

                <div className='col-md-2'>
                  {this.state.isFiltered && (
                    <span className='reload-btn' onClick={this.handleReload}>
                      <i className='fa fa-refresh' />
                    </span>
                  )}
                </div>
              </div>
            </form>
            <div className='table-responsive'>
              <Table
                bookings={this.props.dashboard.bookings?.data}
                showBookingDetails={this.showBookingDetails}
                handleReview={this.handleReview}
                classname='dashboard_table'
                currentPage={this.state.currentPage}
                handleStatusFilter={this.handleStatusFilter}
              />
            </div>
          </div>
          <div className='row mx-0 result_row'>
            <div className='col-md-6 px-0 left_col'>
              {/* {!this.state.query && !this.state.isFiltered && ( */}
              {this.props.dashboard.bookings?.data?.length ? <h6 className='results'>
                Showing {currentRecordsFrom} - {currentRecordsTo} results of{' '}
                {this.props.dashboard.bookings.pagination?.total}
              </h6> : ""}
              {/* )} */}
            </div>
            <div className='col-md-6 px-0 right_col'>
              {/* {!this.state.query && !this.state.isFiltered && ( */}
              {this.props.dashboard.bookings?.data?.length ? <Pagination
                count={this.props.dashboard.bookings.pagination?.totalPages}
                totalItem={this.props.dashboard.bookings.pagination?.total}
                pageLimit={this.state.pageLimit}
                currentPage={this.state.currentPage}
                handlePageClick={this.handlePageClick}
              /> : ""}
              {/* )} */}
            </div>
          </div>
        </div>

        <ViewBookingDetails
          showViewModal={this.state.showViewModal}
          setShowViewModal={this.handleViewModalClose}
          details={this.state.bookingDetails}
        />

        <ReviewModal
          showReviewModal={this.state.showReviewModal}
          handleReviewModalClose={this.handleReviewModalClose}
          handleApproveRating={this.handleApproveRating}
          review={this.state.review}
          title={this.state.title}
          id={this.state.id}
          isRatingApproved={this.state.isRatingApproved}
        />
      </>
    )
  }
}

Bookings.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getBookings: PropTypes.func.isRequired,
  searchBooking: PropTypes.func.isRequired,
  filterBookings: PropTypes.func.isRequired,
  approveRating: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, {
  getBookings,
  searchBooking,
  filterBookings,
  approveRating
})(Bookings)

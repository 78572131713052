import React from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItem } from 'react-pro-sidebar'
import Icon from '../Icon/Icon'

const MainMenuItem = ({ icon, collapsed, name, link = '', handleClick }) => {
  return (
    <>
      <MenuItem style={{ width: 165 }}>
        <NavLink to={link} onClick={handleClick}>
          <Icon icon={icon} size={collapsed ? '20px' : '14px'} />
          {collapsed ? '' : name}
        </NavLink>
      </MenuItem>
    </>
  )
}

export default MainMenuItem

import { Modal, Button } from 'react-bootstrap';
import React, { useState } from 'react';

const ViewMultiDocument = (props) => {
    const { imageData, show, handleClose, rejectReq, status } = props;

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageData.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + imageData.length) % imageData.length);
    };

    return (
        <>
            <Modal backdrop="static" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Document</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-package' style={{ height: "460px", overflowY: "auto"}}>
                    <img
                        src={imageData[currentIndex]}
                        alt="Document"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        {status === "rejected" ? "" : <Button style={{
                            backgroundColor: "rgb(190 222 237)",
                            borderRadius: "50px",
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#717171",
                            border: "none",
                        }} className='page-link shadow-none' onClick={() => rejectReq()}>
                            Reject
                        </Button>}

                        <div style={{ display: "flex", alignItems: "center" }}>
                            {currentIndex !== 0 && (
                                <Button className='page-link shadow-none mr-2' onClick={prevImage} disabled={currentIndex === 0}>
                                    Previous
                                </Button>
                            )}
                            {(imageData.length > 1 && currentIndex !== imageData.length - 1) && (
                                <Button className='page-link shadow-none' onClick={nextImage} disabled={currentIndex === imageData.length - 1}>
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ViewMultiDocument;

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import LazyLoadImage from '../../common/Lazy/Image'
import ExportDataAsCSV from '../../common/Modal/ExportDataAsCSV'
import { api_url } from '../../../utils/api'
import DeleteModal from '../../common/Modal/DeleteModal'
import { accpetWalkerRequest } from '../../../actions/dashboardActions'
import ViewDocument from '../../common/Modal/ViewDocument'

const Table = (props) => {
  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [viewDocs, setViewDocs] = useState(false);
  const [img, setImg] = useState(null);
  const [open, setOpen] = useState({
    open: false,
    serviceProviderId: '',
    flag: '',
    serviceType: ""
  })
  const [walkers, setWalkers] = useState(props.walkers)
  const [expandedRows, setExpandedRows] = useState([]);

  const { accessRole } = props
  const history = useHistory()
  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleRowClick = (id) => {
    setExpandedRows(expandedRows.includes(id)
      ? expandedRows.filter(rowId => rowId !== id)
      : [...expandedRows, id]);
  };

  const handleNoBtnClick = () => {
    setShowModal(false)
  }


  const handleYesBtnClick = () => {
    setShowModal(false);
  }

  const handleClickAccept = () => {
    props.acceptrejectModalClick(open, props.currentPage);
    setOpen({
      open: false,
      serviceProviderId: '',
      flag: '',
      serviceType: ""
    })
  }

  useEffect(() => {
    setWalkers(props.walkers)
  }, [props.walkers])

  useEffect(() => {
    if (img) {
      setViewDocs(true);
    } else {
      setViewDocs(false);
    }
  }, [img])

  const NestedTable = ({ data }) => {
    return (
      <table className="table nested-table">
        <thead>
          <tr>
            <th className="center-text"></th>
            <th className="center-text">Service</th>
            <th className="center-text">Approval Status</th>
            {/* <th className="center-text">Action</th> */}
            <th className="center-text"></th>
          </tr>
        </thead>
        <tbody>
          {data?.services?.map(service => (
            <>
              {service.serviceProviderType ? (
                <tr key={service?._id}>
                  <td className="center-text"></td>
                  <td className="center-text">{service.serviceProviderType?.charAt(0).toUpperCase() + service.serviceProviderType?.slice(1)}</td>
                  <td className="center-text">{service.isApproved?.charAt(0).toUpperCase() + service.isApproved?.slice(1)}</td>
                  {/* <td className="center-text">
                  {service.isApproved === "pending" && <img
                    title='Approve'
                    onClick={() => setOpen({
                      open: true,
                      serviceProviderId: data._id,
                      flag: 'approved',
                      serviceType: service.serviceType
                    })}
                    style={{ cursor: 'pointer', paddingLeft: "15px" }}
                    className='logo'
                    src='/approve.svg'
                    alt='logo'
                  />}
                  {service.isApproved === "pending" && <img
                    title='Reject'
                    onClick={() => setOpen({
                      open: true,
                      serviceProviderId: data._id,
                      flag: 'rejected',
                      serviceType: service.serviceType
                    })}
                    style={{ cursor: 'pointer', paddingLeft: "15px" }}
                    className='logo'
                    src='/delete.svg'
                    alt='logo'
                  />}
                </td> */}
                  <th className="center-text"></th>
                </tr>
              ) : ""}
            </>
          ))}
        </tbody>
      </table>
    )
  }

  const returnProfileStatus = (status) => {

    if (status === "approved") {
      return "Approved";
    } else if (status === "pending") {
      return "Pending";
    } else if (status === "rejected") {
      return "Rejected";
    } else {
      return "Incomplete";
    }
  }


  return (
    <>
      {open.open && <DeleteModal
        showDeleteModal={open.open}
        isButton={open.flag === "approved" ? true : false}
        handleDeleteModalClose={() => setOpen({
          open: false,
          serviceProviderId: '',
          flag: '',
          serviceType: ""
        })}
        title={open.flag === "approved" ? 'Approve Request' : 'Reject Request'}
        text={open.flag === "approved" ? "Are you sure you want to approve this service provider's request?" : "Are you sure you want to reject this service provider's request?"}
        handleYesBtnClick={handleClickAccept}
        handleNoBtnClick={() => setOpen({
          open: false,
          serviceProviderId: '',
          flag: '',
          serviceType: ""
        })}
      />}

      <table className={`table ${props.classname}`}>
        <thead>
          <tr>
            <th>#</th>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Services</th>
            <th>Average Rating</th>
            <th>Date Registered</th>
            {/* <th>Active</th>
            <th>Approval</th>
            <th>Insurance</th> */}
            <th>Profile Status</th>
            <th>Identity Document</th>
            <th className='mw-210'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {walkers &&
            walkers.length > 0 ?
            walkers.map((el, index) => (
              <React.Fragment key={el._id}>
                <tr key={el._id} style={{ cursor: 'pointer' }}>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "10px"
                    }}>{((props.currentPage - 1) * 10) + (index + 1)}
                  </td>
                  <td >
                    <LazyLoadImage
                      src={el.basicInfo.image || api_url + '/default.png'}
                      alt='doctor-image'
                      width='50'
                    />
                  </td>
                  <td >{el.basicInfo.fullName ? el.basicInfo.fullName : '-'}</td>
                  <td >{el.basicInfo.email}</td>
                  <td >{el.services?.length ? el.services?.length > 1 ? <div className='d-flex'>{el.services[0]?.serviceProviderType} <p className='plus-text ml-1' title={el.services.map(ele => ele.serviceProviderType)?.join(", ")}>+{el.services.length - 1}</p> </div> : el.services[0]?.serviceProviderType : ""}</td>
                  <td >{el.rating || '-'}</td>
                  <td >{moment(el.createdAt).format('MMM DD, YYYY')}</td>
                  <td >
                    <span className={`${el.basicInfo.profileStatus === "approved" ? 'complete' : el.basicInfo.profileStatus === "pending" ? "pendings" : 'incomplete'}`}>
                      {returnProfileStatus(el.basicInfo.profileStatus)}
                    </span>
                  </td>
                  <td style={{ alignContent: "center", justifyContent: "center", display: "flex"}}>
                    {/* <span
                      className={
                        el.basicInfo.isUserBlocked ? 'suspended' : 'active'
                      }
                    >
                      {el.basicInfo.isUserBlocked ? 'Suspended' : 'Active'}
                    </span> */}
                    {el.basicInfo?.identity?.idProof ? <i className='fa fa-eye' onClick={() => setImg(el.basicInfo?.identity?.idProof)} /> : "-"}
                  </td>
                  {/* {accessRole === '0' ?
                    <td  className='approve'>
                      <span
                        className={el.isApproved ? 'active' : 'suspended'}
                        onClick={() => props.handleApproveUser(el._id)}
                      >
                        {el.isApproved ? 'Approve' : 'Reject'}
                      </span>
                    </td>
                    :
                    <td >
                      <span
                        className={el.isApproved ? 'active' : 'suspended'}
                      >
                        {el.isApproved ? 'Approve' : 'Reject'}
                      </span>
                    </td>
                  } */}
                  {/* {accessRole === '0' ?
                    <td  className='mw-120 approve'>
                      <span
                        className={el.isEnsure ? 'active' : 'suspended'}
                        onClick={() => props.handleEnsureUser(el._id)}
                      >
                        {el.isEnsure ? 'Insured' : 'Not Insure'}
                      </span>
                    </td> :
                    <td  className='mw-120'>
                      <span
                        className={el.isEnsure ? 'active' : 'suspended'}
                      >
                        {el.isEnsure ? 'Insured' : 'Not Insure'}
                      </span>
                    </td>} */}


                  <td className='mw-170'>
                    <div onClick={() => history.push(`/admin/walker-details/${el._id}`, { el })} className='btn'>
                      <i className='fa fa-eye' />
                    </div>
                    {accessRole === '0' ?
                      <>
                        <Link to='#' className='btn'>
                          <i
                            className={el.basicInfo.isUserBlocked ? 'fa fa-unlock' : 'fa fa-lock'}
                            onClick={() => props.handleUnblockUser(el._id)}
                          />
                        </Link>
                        <Link to='#' className='btn'>
                          <i
                            className='fa fa-trash'
                            onClick={() => props.deleteModalClicked(el._id)}
                          />
                        </Link>
                      </> : null}
                  </td>
                </tr>

                {
                  expandedRows.includes(el._id) && (
                    <tr>
                      <td colSpan="12">
                        <NestedTable data={el} />
                      </td>
                    </tr>
                  )
                }
              </React.Fragment>
            )) : null}
        </tbody>
      </table>

      <ViewDocument show={viewDocs} handleClose={() => { setImg(null); setViewDocs(false) }} url={img} />

      <ExportDataAsCSV
        showModal={showModal}
        handleModalClose={handleModalClose}
        title='Export Data as CSV'
        text='Are you sure you want to export data in csv format?'
        data={data}
        handleNoBtnClick={handleNoBtnClick}
        handleYesBtnClick={handleYesBtnClick}
      />
    </>
  )
}

export default Table

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Navbar from '../common/Navbar/Navbar'
import Sidebar from '../common/Sidebar/Sidebar'
import Loader from '../common/Loader/Loader'
import './styles.scss'

class Admin extends Component {
  constructor () {
    super()
    this.state = {
      collapsed: false,
      toggled: false
    }
  }

  componentDidMount () {
    if (!localStorage.getItem('jwtToken')) {
      this.props.history.push('/admin/login')
    }
  }

  handleToggle = (value) => {
    if (value === 'collapsed') { this.setState({ collapsed: !this.state.collapsed }) }

    if (value === 'toggled') this.setState({ toggled: !this.state.toggled })
  }

  render () {
    const { collapsed, toggled } = this.state

    return (
      <>
        {this.props.dashboard.loading && <Loader />}

        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggle={this.handleToggle}
        />
        <div className={`right_content ${collapsed ? 'collapsed' : ''}`}>
          <Navbar
            handleToggle={this.handleToggle}
            collapsed={collapsed}
            toggled={toggled}
          />
          <div className='main'>{this.props.children(this.state.toggled)}</div>
        </div>
      </>
    )
  }
}

Admin.propTypes = {
  dashboard: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, {})(Admin)

import React from 'react'

const FormInputSuffix = (props) => {

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value)) {
            props.handleChange(e);
        }
    };

    return (
        <>
            <div className={`form-group input-icons ${props.classname}`}>
                <i className={props.icon} style={{ fontSize: props.size }} />
                <input
                    type={props.type}
                    name={props.name}
                    className='form-control shadow-none'
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={handleInputChange}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    style={{ paddingRight: props.suffix ? '3ch' : 'inherit' }}
                />
                {props.suffix && (
                    <span
                        style={{
                            position: 'absolute',
                            right: '0px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                            // backgroundColor: "#f4eeee",
                            backgroundColor: "#dcdcdc",
                            padding: "11px",
                            borderTopRightRadius: "50%",
                            borderRadiusRightTop: "50%",
                            borderBottomRightRadius: "50%",
                            borderLeft: "1px solid #dcdcdc",
                            // fontSize: "12px",
                            border : "2px solid #f1f1f1"

                        }}
                    >
                        {props.suffix}
                    </span>
                )}
            </div >
        </>
    )
}

export default FormInputSuffix;

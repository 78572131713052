

import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getWalker, updateWalker } from '../../../actions/dashboardActions';
import { api_url } from '../../../utils/api';
import Search from '../../common/Search/Search';
import { Modal } from 'react-bootstrap'
import Table from './Table';
import FormInput from '../../common/Form-Input/FormInput';
import Button from '../../common/Button/Button';
import FormInputSuffix from '../../common/Form-Input/FormInputSuffix';

const ConvinienceFee = () => {
    const token = localStorage.getItem('jwtToken');
    const [list, setList] = useState([]);
    const [openModal, setOpenModal] = useState({
        open: false, id: "", fee: ""
    });

    const getTableData = () => {
        axios.get(api_url + `/api/admin/convinience/get/all`, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    setList(res.data.data.convinienceFee);
                }
            })
            .catch((err) => console.log(err))
    }

    const updateData = () => {
        const payload = {
            id: openModal.id,
            convenienceFeePercentage: parseInt(openModal.fee)
        }
        axios.put(api_url + `/api/admin/convinience/update`, payload, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    getTableData();
                    handleClose();
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => { getTableData() }, []);

    const handleOpenModal = (id, fee, type) => {
        setOpenModal({
            open: true, id: id, fee: fee, type: type
        });
    }

    const handleClose = () => {
        setOpenModal({
            open: false, id: "", fee: ""
        });
    }

    const findServiceType = (serviceProviderType) => {
        switch (serviceProviderType) {
            case 'walking':
                return "Walking";
            case 'grooming':
                return "Grooming";
            case 'sitting':
                return "House Sitting";
            case 'boarding':
                return "Boarding";
            case 'visiting':
                return "Drop-in Visits";
            case 'training':
                return "Training";
            default:
                return "Day";
        }
    }

    return (
        <>
            {openModal.open && <Modal backdrop="static" show={openModal.open} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {findServiceType(openModal.type)} Update Fees Percentage
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-package'>
                    <div className='profile-details'>
                        <FormInputSuffix
                            size='15px'
                            type='text'
                            name='duration'
                            value={openModal.fee}
                            placeholder='Enter duration in days'
                            handleChange={(e) => setOpenModal({ ...openModal, fee: e.target.value })}
                            classname='form-control-2 p-0 mb-3'
                            suffix="%"
                        />

                        <Button
                            value='Update'
                            className='btn btn-primary btn-block update-profile-btn shadow-none'
                            handleClick={() => { updateData() }}
                        />
                    </div>
                </Modal.Body >
            </Modal >}

            <div className='manage-doctor-wrapper manage_walkers_page'>
                <h5 className='text-left'>Convenience Fee Configuration</h5>

                <div className='manage-doctor custom_class'>
                    <div className='table-responsive'>
                        <Table list={list} classname='dashboard_table' handleOpenModal={handleOpenModal} />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
    memo(ConvinienceFee)
)

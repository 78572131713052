import React from 'react'

const Textarea = (props) => {
  return (
    <>
      <div className='form-group input-icons'>
        <i className={props.icon} style={{ fontSize: props.size }} />
        <textarea
          name={props.name}
          rows={props.rows}
          className='form-control shadow-none notificationTextArea'
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly}
        />
      </div>
    </>
  )
}

export default Textarea

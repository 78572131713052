import React from 'react'
import moment from 'moment'
import { api_url } from '../../../utils/api'

const BasicDetails = (props) => {
  const { details } = props
  const basicInfo = details && details.basicInfo
  const image =
    basicInfo && basicInfo.image ? basicInfo.image : api_url + '/default.png'

  const getWord = (name) => {
    const words = name.split(' ');
    const firstLetter = words[0][0];
    const lastLetter = words[words.length - 1][0];
    return firstLetter + lastLetter;
  }

  const returnProfileStatus = (status) => {

    if (status === "approved") {
      return "Approved";
    } else if (status === "pending") {
      return "Pending";
    } else if (status === "rejected") {
      return "Rejected";
    } else if (status === "confirmed") {
      return "Confirmed";
    } else if (status === "ongoing") {
      return "Ongoing";
    } else if (status === "completed") {
      return "Completed";
    } else if (status === "cancelled") {
      return "Cancelled";
    } else {
      return "Incomplete";
    }
  }

  const handleBackgroundColor = (status) => {
    if (status === "approved") {
      return "green";
    } else if (status === "pending") {
      return "#FFA500";
    } else if (status === "rejected") {
      return "red";
    } else if (status === "confirmed") {
      return "green";
    } else if (status === "ongoing") {
      return "#FFA500";
    } else if (status === "completed") {
      return "green";
    } else if (status === "cancelled") {
      return "red";
    } else {
      return "red";
    }
  }

  return (
    <>
      {basicInfo?.image ?
        <img src={image} alt='profile-img' className='details-image' />
        :
        <p className='avatarName'>{basicInfo?.fullName ? getWord(basicInfo?.fullName) : ""}</p>
      }
      <h1>
        {basicInfo ? basicInfo.fullName : ''}{' '}
      </h1>
      <h6>
        {basicInfo ? basicInfo.email : ''}{' '}
        {(basicInfo && basicInfo?.profileStatus) ?
          <sup
            style={{
              color: "#fff",
              backgroundColor: handleBackgroundColor(basicInfo?.profileStatus),
              // padding: "4px 10px 5px 10px",
              fontSize: "12px",
              borderRadius: "4px",
            }}
          >
            {returnProfileStatus(basicInfo.profileStatus)}
          </sup> : ""}
      </h6>

      <p>{details ? moment(details.created_at).format('MMM DD, YYYY') : ''}</p>
    </>
  )
}

export default BasicDetails

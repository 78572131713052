import React from 'react'
import { ListGroup, Modal } from 'react-bootstrap'
import './styles.scss'

const ViewPackageDetails = (props) => {
    const { showViewModal, handleClose, details } = props;

    const findPerRate = () => {
        switch (details.serviceProviderType) {
            case 'walking':
                return "Hour";
            case 'grooming':
                return "Hour";
            case 'sitting':
                return "Hour";
            case 'boarding':
                return "Day";
            case 'visiting':
                return "Hour";
            case 'training':
                return "Day";
            default:
                return "Day";
        }
    }

    const findServiceType = () => {
        switch (details.serviceProviderType) {
            case 'walking':
                return "Walking";
            case 'grooming':
                return "Grooming";
            case 'sitting':
                return "House Sitting";
            case 'boarding':
                return "Boarding";
            case 'visiting':
                return "Drop-in Visits";
            case 'training':
                return "Training";
            default:
                return "Day";
        }
    }

    const thTdStyle = {
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '8px',
    };

    return (
        <>
            <Modal
                show={showViewModal}
                onHide={() => handleClose(false)}
                centered
            >
                <Modal.Header closeButton><Modal.Title>Package Details</Modal.Title></Modal.Header>
                <Modal.Body>
                    <ListGroup variant='flush'>
                        <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                            <p style={{ fontWeight: 600, fontSize: 14 }}>Title{' '}</p>
                            <span>
                                {details.title ? details.title?.replace(/^./, details.title[0].toUpperCase()) : ""}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                            <p style={{ fontWeight: 600, fontSize: 14 }}> Service Type{' '}</p>
                            <span>
                                {findServiceType()}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                            <p style={{ fontWeight: 600, fontSize: 14 }}>Service Duration{' '}</p>
                            <span>
                                {details.duration} {details.durationUnit?.replace(/^./, details.durationUnit[0].toUpperCase())}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 2 }}>Package Included Items</p>
                            <span>
                                {details.description.map((item, index) => (
                                    <div className='col'>
                                        {index + 1}. {item}
                                    </div>
                                ))}
                            </span>

                        </ListGroup.Item>

                        {details.bundle?.length ? <p style={{ fontWeight: 600, fontSize: 14, marginBottom: 2, marginTop: 6 }}>Bundle Details</p> : ""}
                        {/* {details.bundle?.length ? details.bundle.map((ele, index) => (<>
                            <h6 className='bundle-header'>Bundle {index + 1}</h6>
                            <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                                Quantity
                                <span>{ele.quantity}</span>
                            </ListGroup.Item>
                        </>)) : ""} */}
                        {details.bundle?.length ? <table className={`table`}>
                            <thead>
                                <tr>
                                    <th style={thTdStyle}>Name</th>
                                    <th style={thTdStyle}>Quantity</th>
                                    <th style={thTdStyle}>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.bundle &&
                                    details.bundle?.length ?
                                    details.bundle.map((ele, index) => (
                                        <tr >
                                            <td style={thTdStyle}>Bundle {index + 1}</td>
                                            <td style={thTdStyle}>{ele.quantity}</td>
                                            <td style={thTdStyle} className='max-w'>{ele.expiresInDays} Day</td>
                                        </tr>
                                    )) : null}
                            </tbody>
                        </table> : ""}

                    </ListGroup>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ViewPackageDetails

import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Form from './Form'
import BasicDetails from './BasicDetails'
import BookingHistory from './BookingHistory'
import { getOwner, updateOwner } from '../../../actions/dashboardActions'
import { api_url } from '../../../utils/api'
import axios from 'axios'
import Pagination from '../../common/Pagination/Pagination'

const OwnerDetails = (props) => {
  const { id } = useParams()
  const token = localStorage.getItem('jwtToken')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [tableData, setTableData] = useState({
    data: [],
    totalBookings: 0,
    totalPages: 0
  })
  const [payload, setPayload] = useState({
    userType: "owner",
    page: 1,
    limit: 10
  });

  const getData = () => {
    const params = new URLSearchParams(payload).toString();
    const fullUrl = `${api_url}/api/admin/get/booking/${id}?${params}`;
    // const fullUrl = `${api_url}/api/admin/get/booking/6679109a4248664bcc0dbf37?${params}`;
    axios.get(fullUrl, payload, { headers: { 'x-access-token': token } })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => console.log(err))
  };

  const handlePageClick = (pageNumber) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      page: pageNumber,
    }));
  }

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id, payload.page]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken')
    props.getOwner(id, token)
  }, [])
  useEffect(() => {
    if (props.dashboard.singleOwnerInfo.basicInfo) {
      setFullName(props.dashboard.singleOwnerInfo.basicInfo.fullName)
      setEmail(props.dashboard.singleOwnerInfo.basicInfo.email)
      setPhoneNumber(props.dashboard.singleOwnerInfo.basicInfo.phoneNumber)
    }
  }, [props])

  const handleDetailsUpdate = (e) => {
    e.preventDefault()

    const token = localStorage.getItem('jwtToken')

    const owner = {
      id,
      fullName,
      email,
      phoneNumber
    }

    props.updateOwner(owner, token)
    props.getOwner(id, token)
  }

  const startResult = (payload.page - 1) * payload.limit + 1;
  const endResult = Math.min(payload.page * payload.limit, tableData.totalBookings);

  return (
    <>
      <div className='patient-details'>
        <h5 className='text-left'>Owner Details</h5>
        <div className='row mx-0'>
          <div className='col-md-4 pl-0'>
            <div className='basic-details'>
              <BasicDetails
                details={
                  props.dashboard.singleOwnerInfo
                    ? props.dashboard.singleOwnerInfo
                    : ''
                }
              />
            </div>
          </div>
          <div className='col-md-8 pr-0'>
            <div className='personal-details'>
              <h5 className='text-left'>Personal Details</h5>
              <Form
                fullName={fullName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                handleDetailsUpdate={handleDetailsUpdate}
                accessRole={props.accessRole}
              />
            </div>
          </div>
        </div>

        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='appointment-history'>
              <h5 className='text-left'>Booking History</h5>



              <div className='table-responsive'>
                {' '}
                <BookingHistory
                  data={tableData.data}
                  ownerInfo={props.dashboard.ownerInfo}
                  currentPage={payload.page}
                />
              </div>

            </div>

          </div>
        </div>
        {tableData?.totalBookings ? <div className='row result_row mx-0'>
          <div className='col-md-6 px-0 left_col'>
            <h6 className='results'>
              Showing {startResult} - {endResult} results of{' '}
              {tableData?.totalBookings}
            </h6>
          </div>
          <div className='col-md-6 px-0 right_col'>
            <Pagination
              count={tableData?.totalPages ? tableData?.totalPages : 0}
              totalItem={tableData?.totalBookings ? tableData?.totalBookings : 0}
              pageLimit={10}
              currentPage={payload.page}
              handlePageClick={handlePageClick}
            />
          </div>
        </div> : ""}
      </div>
    </>
  )
}

OwnerDetails.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getOwner: PropTypes.func.isRequired,
  updateOwner: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole

})

export default connect(mapStateToProps, { getOwner, updateOwner })(
  memo(OwnerDetails)
)

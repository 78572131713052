import React from 'react';

const Table = (props) => {
    const { list, classname } = props;

    return (
        <>
            <table className={`table ${classname}`}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {list && list?.length ?
                        list.map((ele, index) => (
                            <tr >
                                <td>{index + 1}</td>
                                <td>{ele.name}</td>
                                <td ><span className={ele.status}>{ele.status}</span></td>
                                <td>{ele.type}</td>
                            </tr>
                        )) : null}
                </tbody>
            </table>
        </>
    )
}

export default Table;

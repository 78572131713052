import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button/Button'
import './styles.scss'

const DeleteModalFundRequest = (props) => {
  return (
    <>
      <Modal
        show={props.showDeleteModal}
        onHide={props.handleDeleteModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.text}</Modal.Body>
        <Modal.Footer>
          <Button
            value='Cancel'
            className={props?.isButton ? 'btn btn-danger shadow-none yes-btn custom-button-color-black' : 'btn shadow-none no-btn btn-success custom-button-color-black'}
            type='button'
            handleClick={props.handleNoBtnClick}
          />
          <Button
            value='Yes'
            className={'btn shadow-none no-btn btn-success'}
            type='button'
            handleClick={props.handleYesBtnClick}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteModalFundRequest;

import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import FormInput from '../../common/Form-Input/FormInput'
import Button from '../../common/Button/Button'

const Form = (props) => {
  return (
    <>
      <form onSubmit={props.handleDetailsUpdate}>
        <FormInput
          icon='fa fa-user icon'
          size='15px'
          type='text'
          name='fullName'
          placeholder='Full Name'
          value={props.fullName}
        />
        <FormInput
          icon='fa fa-envelope icon'
          size='13px'
          type='email'
          name='email'
          placeholder='Email'
          className='form-control shadow-none'
          value={props.email}
          handleChange={(e) => props.setEmail(e.target.value)}
        />
        <FormInput
          icon='fa fa-phone icon'
          type='number'
          name='phoneNumber'
          placeholder='Phone Number'
          className='form-control shadow-none'
          value={props.phoneNumber}
          handleChange={(e) => props.setPhoneNumber(e.target.value)}
        />
        {props.accessRole ==='0' ?
        <div className='form-group mb-0'>
          <Button
            value='Update Details'
            className='btn  btn-block update-details-btn shadow-none text-white'
            type='submit'
          />
        </div>
        :null}
      </form>
    </>
  )
}

export default Form

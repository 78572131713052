import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Modal, ListGroup } from 'react-bootstrap'

import { api_url } from '../../../utils/api'
import './styles.scss'

const ReviewModal = (props) => {
  console.log('review props', props.isRatingApproved)
  return (
    <>
      <Modal
        show={props.showReviewModal}
        onHide={() => props.handleReviewModalClose()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title && props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant='flush'>
            <>
              <ListGroup.Item className='d-flex justify-content-between align-items-center'>
                Review{' '}
                <span>
                  {props.review && props.review}
                </span>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex justify-content-center align-items-center'>
                <span
                  className={props.isRatingApproved ? 'suspended' : 'active'}
                  onClick={() => props.handleApproveRating(props.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {props.isRatingApproved ? 'Reject' : 'Approve'}
                </span>
              </ListGroup.Item>
            </>
          </ListGroup>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default ReviewModal

import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Form from './Form'
import BasicDetails from './BasicDetails'
import BookingHistory from './BookingHistory'
import { getUserDetail, updateOwner } from '../../../actions/dashboardActions'

const DeletedUserDetail = (props) => {
  const { id , accountType } = useParams()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('jwtToken')
    props.getUserDetail(id, token)
  }, [])
  useEffect(() => {
    if (props.dashboard.userInfo) {
      setFullName(props.dashboard.userInfo.fullName)
      setEmail(props.dashboard.userInfo.email)
      setPhoneNumber(props.dashboard.userInfo.phoneNumber)
    }
  }, [props])


  return (
    <>
      <div className='patient-details'>
        <h5 className='text-left'>{accountType ==='owner' ? ' Owner Details ' :  ' Service Provider Details ' } </h5>
        <div className='row mx-0'>
          <div className='col-md-4 pl-0'>
            <div className='basic-details'>
              <BasicDetails
                details={
                  props.dashboard.userInfo
                    ? props.dashboard.userInfo
                    : ''
                }
              />
            </div>
          </div>
          <div className='col-md-8 pr-0'>
            <div className='personal-details'>
              <h5 className='text-left'>Personal Details</h5>
              <Form
                fullName={fullName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                accessRole={props.accessRole}
              />
            </div>
          </div>
        </div>

        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <div className='appointment-history'>
              <h5 className='text-left'>Booking History</h5>
              <div className='table-responsive'>
                {' '}
                <BookingHistory
                  data={props.dashboard.userBookings}
                  ownerInfo={props.dashboard.userInfo}
                  accountType={accountType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

DeletedUserDetail.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getUserDetail: PropTypes.func.isRequired,
  updateOwner: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole

})

export default connect(mapStateToProps, { getUserDetail, updateOwner })(
  memo(DeletedUserDetail)
)

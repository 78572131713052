import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import LazyLoadImage from '../../common/Lazy/Image'
import { api_url } from '../../../utils/api'

const Table = (props) => {
  return (
    <>
      <table className={`table ${props.classname}`}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Email</th>
            <th className='mw-150'>Type</th>
            <th className='mw-150'>Date Registered</th>
            <th className='mw-150'>Deletion Date</th>
            <th className='mw-210'>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.deletedUser &&
            props.deletedUser.length > 0 &&
            props.deletedUser.map((p, index) => (
              <tr key={p._id}>
                <td>{index + 1}</td>
                <td>
                  <LazyLoadImage
                    src={
                      p && p.photoId
                        ? p.photoId
                        : api_url + '/default.png'
                    }
                    alt='profile'
                  />
                </td>

                <td>{p.fullName || '-'}</td>
                <td>{p.email || '-'}</td>
                <td>{p.accountType || '-'}</td>
                <td>{moment(p.created_at).format('MMM DD, YYYY')}</td>
                <td>{moment(p.deleted_at).format('MMM DD, YYYY')}</td>
                <td>
                  <Link
                    to={`/admin/deleted/user-details/${p._id}/${p.accountType}`}
                    className='btn edit'
                  >
                    <i className='fa fa-eye' />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default Table
